<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          资金明细
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <!--按钮组-->
          <b-dropdown variant="primary" right text="导入导出" class="m-2">
            <b-dropdown-item-button class="rounded" @click="exportWithdraw">
              <i class="flaticon-download-1 mr-2"></i>
              导出付款订单
            </b-dropdown-item-button>
          </b-dropdown>
          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <!--表格-->
          <v-data-table show-select fixed-header dense height="70vh" v-model="table.selected" :headers="table.headers"
            :items="table.desserts" :single-select="table.singleSelect" :footer-props="table.footer"
            :server-items-length="table.itemsCount" :options.sync="table.pagination" :no-data-text="table.noDataText"
            :loading="table.loading" :loading-text="table.loadingText" item-key="id">


            <template v-slot:item.type="{ item }">
              {{ filter.typeOptions.find((typeItem) => typeItem.value == item.type).text }}
            </template>

            <template v-slot:item.amount="{ item }">
              {{ item.amount / 100.00 }}
            </template>

            <template v-slot:item.originAmount="{ item }">
              {{ item.originAmount / 100.00 }}
            </template>

            <template v-slot:item.createTime="{ item }">
              {{ item.createTime | formatDate }}
            </template>


            <!--表格筛选-->
            <template v-slot:top>
              <b-form inline>
                <b-form-group class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0">
                  <b-form-select :options="filter.typeOptions" v-model="filter.type"></b-form-select>
                </b-form-group>

                <b-form-datepicker locale="zh" v-model="filter.startTime" placeholder="起始日期" :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"></b-form-datepicker>

                <b-form-datepicker class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0" locale="zh"
                  v-model="filter.endTime" placeholder="结束日期" :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"></b-form-datepicker>

                <b-form-group class="mx-2">
                  <b-button variant="success" v-on:click="getData()">
                    <v-icon small> flaticon-search</v-icon>
                    查询
                  </b-button>
                </b-form-group>
              </b-form>

              <!--分隔线-->
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </div>
      </template>
    </KTCard>




  </div>
</template>

<script>
import Vue from "vue";

import KTCard from "@/view/content/Card.vue";
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";

// FormValidation plugins

import Swal from "sweetalert2";

export default {
  name: "List",
  components: {
    KTCard,
  },
  props: {
    title: String,
  },
  data() {
    return {

      filter: {
        typeOptions: [{
          value: null,
          text: "类型"
        },
        {
          value: 1,
          text: "订单结算"
        },
        {
          value: 2,
          text: "商户提现"
        },
        {
          value: 3,
          text: "加款"
        },
        {
          value: 4,
          text: "扣款"
        },
        {
          value: 5,
          text: "冻结"
        },
        {
          value: 6,
          text: "解冻"
        },
        ],
        type: null,
        startTime: "",
        endTime: "",
      },


      table: {
        loadingText: "正在加载...",
        noDataText: "暂无数据...",
        loading: false,
        singleSelect: false,
        selected: [],
        itemsCount: 10,
        headers: [{
          text: "操作类型",
          value: "type"
        },
        {
          text: "原金额",
          value: "originAmount"
        },
        {
          text: "金额",
          value: "amount"
        },
        {
          text: "操作时间",
          value: "createTime"
        },
        {
          text: "订单号",
          value: "remark"
        },
        ],
        footer: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          itemsPerPageText: "分页数量",
          showCurrentPage: true,
        },
        pagination: {
          itemsPerPage: 20,
        },
        desserts: [],
      },
    };
  },
  mounted() {

  },
  created() { },

  methods: {

    getData() {
      let startTime = this.filter.startTime, endTime = this.filter.endTime;
      if (startTime) {
        startTime = startTime + " 00:00:00";
      }
      if (endTime) {
        endTime = endTime + " 23:59:59";
      }
      let params = {
        pageNum: this.table.pagination.page,
        pageSize: this.table.pagination.itemsPerPage,
        type: this.filter.type,
        startTime: startTime,
        endTime: endTime,
      };

      ApiService.query("/api/merchant/account/bills/query_list", {
        params: params,
      }).then(({
        data
      }) => {
        this.table.desserts = data.result.data;
        this.table.itemsCount = data.result.total;
      });
    },
    exportWithdraw() {
      let startTime = this.filter.startTime, endTime = this.filter.endTime;
      if (startTime) {
        startTime = startTime + " 00:00:00";
      }
      if (endTime) {
        endTime = endTime + " 23:59:59";
      }
      let params = {
        pageNum: this.table.pagination.page,
        pageSize: this.table.pagination.itemsPerPage,
        type: this.filter.type,
        startTime: startTime,
        endTime: endTime,
      };

      ApiService.query("/api/merchant/account/bills/export_bills", {
        params: params,
        responseType: "blob",
      }).then((res) => {
        if (res.status == 200) {
          // 截取文件名，这里是后端返回了文件名+后缀，如果没有可以自己拼接
          let fileName = decodeURI(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          );
          // 将`blob`对象转化成一个可访问的`url`
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    },
    getSelectedIds() {
      let ids = [];
      if (this.table.selected != null && this.table.selected != undefined) {
        this.table.selected.forEach(function (item) {
          ids.push(item.id);
        });
      }
      return ids;
    },
  },
  computed: {
    pagination: function () {
      return this.table.pagination;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>